<template>
  <!-- v-bind:class = "(isLoaded)?'loaded-preview':''" -->
  <section class="preview">
    <header class="preview__header">
      <div class="preview__header-container">
        <p class="preview__name">
          Партнерская сеть
        </p>

        <img class="preview__logo" src="@/assets/images/coming-soon/logo.svg" alt="Лого">

        <p class="preview__date">
          10 декабря 2022
        </p>
      </div>
    </header>

    <h1 class="type-js preview__title">
      <span class="text-js">Coming soon..</span>
    </h1>

    <div class="preview__animation-wrapper">
      <svg class="preview__svg" viewBox="0 0 468 468" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="svg">
          <path id="svg-star-start"
            d="M28.9485 0L31.6899 17.603C32.3734 21.9924 35.8192 25.4346 40.2093 26.1136L57.8969 28.8493L40.2093 31.5851C35.8192 32.2641 32.3734 35.7063 31.6899 40.0956L28.9485 57.6986L26.207 40.0956C25.5235 35.7063 22.0777 32.2641 17.6876 31.5851L0 28.8493L17.6876 26.1136C22.0777 25.4346 25.5235 21.9924 26.207 17.603L28.9485 0Z"
            fill="black" />
          <g id="path-group">
            <path id="svg-path"
              d="M52.268 28.8493C118.474 37.1301 245.901 75.9699 225.959 168.288C201.031 283.685 135.897 262.048 125.443 330.164C114.99 398.281 175.299 528.103 403.67 434.342"
              stroke="black" />
            <path id="svg-star-end"
              d="M434.227 400.685L437.189 419.704C437.949 424.583 438.328 427.022 439.946 428.638C441.564 430.254 444.003 430.631 448.882 431.386L468 434.342L448.882 437.299C444.003 438.054 441.564 438.431 439.946 440.047C438.328 441.663 437.949 444.102 437.189 448.981L434.227 468L431.265 448.981C430.505 444.102 430.125 441.663 428.508 440.047C426.89 438.431 424.451 438.054 419.572 437.299L400.454 434.342L419.572 431.386C424.451 430.631 426.89 430.254 428.508 428.638C430.125 427.022 430.505 424.583 431.265 419.704L434.227 400.685Z"
              fill="black" />
          </g>
        </g>
      </svg>

      <!--  style="display: none" -->
      <picture class="preview__phumb" >
        <source srcset="@/assets/images/coming-soon/preview-phumb-mobile.png 1x, @/assets/images/coming-soon/preview-phumb-mobile@2.png 2x" media="(max-width: 600px)">
        <source srcset="@/assets/images/coming-soon/preview-phumb-mobile.webp 1x, @/assets/images/coming-soon/preview-phumb-mobile@2.webp 2x" media="(max-width: 600px)">
        <source type="image/png" srcset="@/assets/images/coming-soon/preview-phumb.png 1x, @/assets/images/coming-soon/preview-phumb@2.png 2x">
        <source type="image/webp" srcset="@/assets/images/coming-soon/preview-phumb.webp 1x, @/assets/images/coming-soon/preview-phumb@2.webp 2x">
        <img src="@/assets/images/coming-soon/preview-phumb.png"
          srcset="@/assets/images/coming-soon/preview-phumb@2.png 2x" alt="">
      </picture>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ComingSoon',
  // data() {
  //   return {
  //     isLoaded: false,
  //   }
  // },
  methods: {
    autoTypeJS(elementClass, typingSpeed){
      let thisEl = document.querySelector(elementClass);
      thisEl.style.position = "relative";
      thisEl.style.display = "inline-block"

      const cursorEl = document.createElement('div');
      cursorEl.classList.add('cursor');
      cursorEl.style.right = "initial";
      cursorEl.style.left = `0`;
      thisEl.prepend(cursorEl);

      const textEl = thisEl.querySelector(".text-js");

      let text = textEl.textContent.trim().split('');
      let amntOfChars = text.length;
      let newString = "";
      textEl.textContent = "|";
      setTimeout(function () {
        textEl.style.opacity = 1;
        if (textEl.previousElementSibling) {
          textEl.previousElementSibling.style = "";
          textEl.previousElementSibling.removeAttribute("style")
        }
        textEl.textContent = "";
        for (var i = 0; i < amntOfChars; i++) {
          (function (i, char) {
            setTimeout(function () {
              newString += char;
              textEl.textContent = newString;
              // if(i+1 == amntOfChars) {
              //   cursorEl.style.visibility = 'hidden';
              // }
            }, i * typingSpeed);
          })(i + 1, text[i]);
        }
      }, 1700);
    }
  },
  async mounted() {
  const animationTime = 3.5;
  // const animationTime = 1 + 1.5 + 1.5 + 0.7 + 1.5 + 0.7;
  setTimeout(() => {
    this.autoTypeJS(".type-js", 200);
  }, animationTime * 1000)
},
}
</script>

<!-- 
  Don't use "scoped" since Vue will broke keyframes animation!
-->
<style lang="scss" src="./comingSoon.scss" >

</style>